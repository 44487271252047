.month-wise{
    width: 85%;
    margin : 20px 0px;
    border : 1px solid #adadad;
    border-radius: 10px;
    padding : 10px 20px;
    color: #717171;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    font-size: 0.75rem;
}

.month-wise h3{
    font-size: 0.9rem;
    font-weight: 500;
    color: #717171;
}

.toggle-container{
    display: flex;
    align-items: center;
}

.month-wise .row {
    display: flex;
    justify-content: start;
    margin: 10px 0px;
    align-items: center;
}

.toggle-container .p{
    color: #adadad;
    font-size: 0.9rem !important;
}



.wise {
    color: #717171 !important ;
    font-size: 0.9rem !important;
}
.consultant-selection-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.consultant-selection-header .icon-container{
    display: flex;
    width: 10%;
    justify-content: end ;
    align-items: flex-start !important;
}

.icon {
    cursor: pointer;
}

.productType{
    display: flex;
    align-items: center;
}

.productList{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.productName{
    width: 40%;
}