.ql-editor {
  overflow-y: auto !important;
}

.ql-container {
  height: 300px;
  /* Replace with your minimum height value */
}

.msg {
  height: calc(100% - 18px);
  overflow: scroll;
  color: #000;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 8px;
  padding: 8px;
}
.msg::-webkit-scrollbar{
  display: none;
}