.cancel-button {
    color: #D32C2C;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: transparent;
}
.cancel-button:disabled {
    color: #616161;
    cursor: not-allowed;
}
.dialog-content {
    display: flex;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    font-size: 1rem;
    line-height: '30px';
    margin-bottom: 0;
    color: #616161;
    margin: 10px auto;
}

.dialog-content-container {
    font-weight: 500;
    color: #313131;
    /* padding-left: 40px;
    padding-right: 40px; */
    width: 100%;
}

.dialog-content-heading {
    padding-left: 0;
    padding-bottom: 20px;
    padding-top: 0;
    border-bottom: 1px solid #919191;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #313131;
}

.dialog-success {
    font-size: 14px;
    color: #009727;
    text-align: center;
    padding: 0;
    margin: 0;
}

.dialog-error {
    font-size: 14px;
    color: #D32C2C;
    text-align: center;
    padding: 0;
    margin: 0;
}

.dialog-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid #919191;
    padding-top: 20px;
    padding-bottom: 10px;
}

.dialog-cancel-button {
    border: 1px solid #1B99DF;
    background: #FFFFFF;
    border-radius: 10px;
    color: #1B99DF;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 75px;
    margin: 0 5px;
}

.dialog-confirm-button {
    border: 1px solid #1B99DF;
    background: #1B99DF;
    border-radius: 10px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    padding: 6px 75px;
    margin: 0 5px;
}

.dialog-confirm-button:hover {
    background: #1B99DF;
    color: #ffffff;
}
.header-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input-container{
    width: 50%;
    padding: 10px;
}
.select-input{
    width: 100%;
}
.search-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0px;
}
.table-container{
    width: 100%;
    padding: 10px;
    border: 1px solid #adadad;
    border-radius: 10px;
    margin-top: 20px;
}