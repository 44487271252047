.header-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
  }
.input-container1{
    width: 35%;
    padding: 0px 20px;
  }
.input-container2{
    width: 65%;
    padding: 0px 20px;
  }

.box-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.note{
    padding: 20px;
}
.box-row p{
    font-size: 1rem;
    font-weight: 500;
    color: #313131;
}
.note p{
    font-size: 0.75rem;
    font-weight: 500;
    color: #616161;
}