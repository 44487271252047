.userData{
    width: 18%;
    overflow: hidden;
    padding: 20px;
    margin: 0;
}
.userData1{
    width: 46% ;
    overflow: hidden;
    padding: 20px;
    margin: 0;
}
.userData2{
    width: 28%;
    overflow: hidden;
    padding: 20px;
    margin: 0;
}

.smallUserData{
    width: 25%;
    margin: 0;
    padding: 0;
}

.userDataSection{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

h3 {
    color: black;
}