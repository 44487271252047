.header-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
  }
  .box-row-slots .input-container1{
    width: 25%;
    padding: 0px 20px;
  }
.box-row-slots .input-container2{
    width: 40%;
    padding: 0px 20px;
  }

.box-row-slots{
    display: flex;
    align-items: center;
}
.note{
    padding: 20px;
}

.workingDays{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.weekDay{
  width:100%
}
.box-row .heading{
    font-size: 1.2rem;
    font-weight: 500;
    color: #313131;
}
.box-row p{
  font-size: 1rem;
  font-weight: 500;
  color: #313131;
}
.note p{
  font-size: 0.75rem;
  font-weight: 500;
  color: #616161;
}