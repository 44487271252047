.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1rem;
}

.column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.row p {
    color: black;
    font-weight: 500;
}

table{
    width: 100%;
    margin: 5px;
}

table .header th {
    font-weight: 500;
    color: black;
    border-bottom: 1px solid black;
    text-align: left;
    font-size: 1rem;
}

table tr{
    font-size: 0.9rem;
}

.text-field-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-field-label{
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;
    color: black;
}
.recipe-measurement{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #adadad;
}

.recipe-measurement .row {
    padding: 10px;
}
.measurement-row{
    width: 70%;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    padding: 0px 10px;
}

.measurement-row-input {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 10px 10px;
}

.measurement-row-input > div {
    display: flex;
    align-items: center;
}

.measurement-icon{
    font-size: 1.5rem;
}

.measurement-button-container{
    display: flex;
    align-items: center;
}
.recipe-data-container{
    width: 100%;
    border-radius: 10px;
    border: 1px solid #adadad;
    overflow: hidden;
}
 .recipe-data-container .row{
    padding: 10px;
}

.recipe-data-container .header {
    border-bottom: 1px solid #d3d3d3;
}

.recipe-data-container .heading {
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}

.recipe-measurement .heading{
    color: black;
    font-weight: 500;
    font-size: 1.2rem;
}

.search-bar{
    display: flex;
    flex-direction: row;
    align-items: center;
}
/* .search-bar input{
    border: 1px solid #adadad;
    border-radius: 20px 0px 0px 20px;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
} */
/* .search-bar input::placeholder{
    color: #919191;
    font-size: 1rem;
}

.search-bar input:focus{
    outline: 1px solid #1B99DF;
} */



.search-bar .search-icon-container{
    margin-left: -25px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1B99DF;
    border-radius: 0px 25px 25px 0px;
    border: 4px solid #1B99DF;
    padding: 10px;
    font-size: 0.8rem;
    color: white;
}
.hide-custom{
    display: none;
}
.show-custom{
    display: block;
}
.measurement-name{
    background-color: #eaeaea;
    font-size: 0.9rem;
    color: #212121;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0;
    width: 100%;
}
.measurement-value{
    background-color: #eaeaea;
    font-size: 0.9rem;
    color: #212121;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0;
    min-width: 10vw;
    width: 20%;
    margin-right: 1rem;
}

/* .autocomplete-tags {
    width: 49%;
  } */
  