.selection-row{
    width: 100%;
    padding: 20px;
}
.selection-row .heading{
    font-size: 1rem;
    font-weight: 500;
    color: #313131;
    margin-top: 0px;
    margin-bottom : 10px;
}
.selection-row .content-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.selection-row .expert-info{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.selection-row .info-box{
    width: 20%;
    min-width: 200px;
    margin: 10px 20px 10px 0px;
    padding: 10px;
    background-color:#eaeaea ;
    border-radius: 10px;
}
.content-container .meta{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.selection-row .time-selection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.selection-row .month{
    width : 20%;
    min-width: 200px;
    margin: 10px 20px 10px 0px;
}
.select_slots_child{
    /* width : 160px; */
}
.selection-row .availability{
    width: 50%;
    min-width: 400px;
    margin: 10px 20px 10px 0px;
}
.selection-row .time-input{
    width: 45%;
    min-width: 200px;
}
.week-selection{
    display: flex;
    flex-direction: row;;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 20px;
    width: 100%;
    position: relative;
}
.select-slots-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.clear-button{
    color: #D32C2C;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.select-button{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select-slots-header .button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dayActiveTab .Mui-selected{
    background-color: #1B99DF !important;
    color: #fff !important;
}

.dayActiveTab .MuiTabs-indicator{
    display: none !important;
}

.week-selection .heading{
    margin: 0;
}
.tab-container{
    margin-left: 15%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.day-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #adadad;
}

.day-row .date{
    width: 15%;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    color: #313131;
}

.day-row .slots{
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
}

.slot{
    cursor: pointer;
    margin: 15px;
    width: 15%;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #313131;
    border: 1px solid #313131;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.9rem;
}
.selected-slot{
    cursor: pointer;
    margin: 15px;
    width: 15%;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white ;
    background-color: #1B99DF;
    border: 1px solid #1B99DF;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.9rem;
}
.disabled-slot{
    margin: 15px;
    width: 15%;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #adadad ;
    background-color: white;
    border: 1px solid #adadad;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.9rem;
}

.row-select-slots-header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 0px;
}

.row-select-slots-header .button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.configuration-row{
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    border-bottom: 3px solid #ADADAD;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
}
.configuration{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.select_time_div{
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.select_time_div_child{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 20px;
    background: #FFFFFF;
    border: 2px solid #ADADAD;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    color: #000;
    min-width: 170px;
    cursor: pointer;
}
.no_availability{
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: #EAEAEA;
    border: 2px solid #EAEAEA;
    border-radius: 10px;
    color: #313131;
}
.select_delete{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.slots{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.none{
    display: none;
}
.block{
    display: block;
}
.input_time{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding-top: 5px;   
    padding-bottom: 5px;    */
    gap: 15px;
}
.input_time_p{
    display: flex;
    flex-direction: column;
}
.input_time_child{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 20px;
    background: #FFFFFF;
    border: 2px solid #ADADAD;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    color: #000;
    width: 40px;
    height: 40px;
}

.input_time_child_normal{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    gap: 20px;
    background: #FFFFFF;
    border: 2px solid #ADADAD;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    color: #000;
    height: 40px;
    width: 200px;

}

.input_time_child_disabled{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    gap: 20px;
    background: #FFFFFF;
    border: 2px solid #eaeaea;
    background-color:#eaeaea ;
    border-radius: 10px;
    color: #000;
    height: 40px;
    width: 200px;

}
.input_time_child2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.done_clear{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
}
.select_slots{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    gap: 30px;
}
.slot_available{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    background: #FFFFFF;
    border: 1px solid #ADADAD;
    color: #000;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    min-height: 30px;
    cursor: pointer;
    min-width:170px;
}
.slot_selected{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    background: #1B99DF;
    border: 1px solid #1B99DF;
    color: #fff;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    min-height: 30px;
    cursor: pointer;
    min-width:170px;
}
.slot_booked{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    background: #D32C2C;
    border: 1px solid #D32C2C;
    color: white;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    min-height: 30px;
    cursor: not-allowed;
    min-width:170px;
}
.slot_disabled{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    background: #EAEAEA;
    border: 1px solid #EAEAEA;
    color: #fff;
    border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
    min-height: 30px;
    cursor: not-allowed;
    min-width:170px;
}
.no_slots{
    display:flex;
    padding:30px;
    align-items:center;
    justify-content:center; 
    background:#EAEAEA;
    border-radius: 10px;
    border: 1px solid #EAEAEA;
    margin-top: 10px;
    color: #000;
    font-size: 18px;
}