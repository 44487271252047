
.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.row p {
    color: black;
    font-weight: 500;
}

table{
    width: 100%;
}

table tr.wrapper:nth-child(even) {background: #EDEDED}
table tr.wrapper:nth-child(odd) {background: #FFF}
table tr.wrapper.background_red{background: #f44336 !important}

table .header th {
    font-weight: 500;
    color: #313131;
    text-align: left;
    background: #EDEDED;
    padding: 15px 10px 15px 10px;
    border: none;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}

table .wrapper td {
    font-weight: 500;
    color: #313131;
    text-align: left;
    padding: 5px 10px 5px 10px;
    border: none;
}

table tr.wrapper.background_red td{
    color: #fff;
}
table tr.wrapper.background_red td button{
    color: #ffffff;
}


.dayActiveTab .Mui-selected{
    background-color: #1B99DF !important;
    color: #fff !important;
}

.dayActiveTab .Mui-selected.approved_day_meal{
    background-color: green !important;
    color: #fff !important;
    border: green 1px solid !important;
}

.clickableItem.opne_sec svg {
    transform: rotate(180deg);
}

.dayActiveTab .MuiTabs-indicator{
    display: none !important;
}

button.save_button{
    pointer-events: none;
}
button.save_button.not_disabled{
    pointer-events: auto;
    background-color: #02a9f4 !important;
}

.select_recepic_modal .css-ifw91t-MuiPaper-root{
    box-shadow: none !important;
}

.select_recepic_modal .css-p3uzlx-MuiTableCell-root-MuiTablePagination-root{
    border: 0;
}
.select_recepic_modal .css-xun6oe-MuiInputBase-root-MuiInput-root {
    border: 2px solid #ADADAD;
    border-radius: 40px;
    padding: 0 0 0 12px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
}
.select_recepic_modal .css-ix00ao-MuiInputBase-input-MuiInput-input{
    padding: 10px 0px;
    border: 0 !important;
}
.select_recepic_modal .css-xun6oe-MuiInputBase-root-MuiInput-root:after,
.select_recepic_modal .css-xun6oe-MuiInputBase-root-MuiInput-root:before{
    display: none;
}
.select_recepic_modal .MuiInputBase-formControl button.MuiButtonBase-root{
    background-color: #1B99DF;
    color: #fff;
    border-radius: 0 20px 20px 0;
}

.select_recepic_modal h6.MuiTypography-h6{
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    color: #313131;
}

.select_recepic_modal .MTableToolbar-root-7{
    padding-left: 0;
}
.select_recepic_modal .MTableToolbar-searchField-12 {
    min-width: 330px;
}

.select_recepic_modal table{
    border-collapse: separate;
    border-spacing: 0;
}

.css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: #313131;
}
.inputRounded input[type="text"],
.inputRounded fieldset{
    background: #FFFFFF;
    border: 1px solid #ADADAD;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: #313131;
 }
 .inputRounded fieldset{
    background-color: transparent;
 }

 table.mealTypeTable,
 .nutritionProfileTable table,
 .nutrientsConsumed table{
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #DDDDDD;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
 }
 table.mealTypeTable{
    border: 1px solid #DDDDDD;
    
 }
 table.mealTypeTable tr td + td,
 table.mealTypeTable tr th + th,
 .nutritionProfileTable table tr td + td,
 .nutritionProfileTable table tr th + th,
 .nutrientsConsumed table tr td + td,
 .nutrientsConsumed table tr th + th{
    border-left: 1px solid #DDDDDD;
 }

 .verdict-icons {
    display: flex;
    align-items: center;
 }

 .show-input-row {
    display: block;
 }

 .hide-input-row{
    display: none;
 }

 .base-type-container{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    margin: 10px 0
 }

 .user-detail-smaill-div{
    width: 15%;
 }

 .mealTypeTable tbody{
    height: 100px;
 }