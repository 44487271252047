.css-1vltytj-MuiTableCell-root{
    padding: 6px 8px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    width: 95px !important;
}

/* .css-a8rsnn-MuiButtonBase-root-MuiTableSortLabel-root{
    width: 224px !important;
} */

.css-6a6auw-MuiTableCell-root{
    padding: 15px !important;
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.Component-horizontalScrollContainer-11{
    border: 2px solid #adadad !important;
    border-radius: 8px !important;
    padding: 8px !important;
}

.css-1b9sep9-MuiButtonBase-root-MuiPaginationItem-root{
    color: #269ee0 !important;
    border: 1px solid #269ee0 !important;
    font-size: 12px !important;
    font-weight: 900 !important;
}

.css-1b9sep9-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #cee7f5 !important;
    border: 1px solid #269ee0 !important;
    color: #269ee0 !important;
    font-size: 12px !important;
    font-weight: 900 !important;
}

.d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.displayFlex{
    display: flex;
    justify-content: space-between;
}

.flexSpan{
    position: relative;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    top: -6px;
    padding: 0 6px;
}

.UserInfo{
    margin: 6px 0 0 0;
}

.listOfUser p{
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.css-w5yzw6-MuiButtonBase-root-MuiButton-root{
    min-width: 28px !important;
}
.BtnUpdate{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 2.75rem;
    margin-top: 20px;
}
.BtnUpdate Button{
    background: #269ee0;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
    padding: 6px 24px;
    margin-left: 10px;
}

.BtnUpdate Button:hover{
    background: #269ee0;
    opacity: 0.8;
}

.css-bv4h8d-MuiFormLabel-root{
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.css-11e0qc7-MuiInputBase-root-MuiOutlinedInput-root{
    color: #000 !important;
    border-radius: 8px !important;
}

.css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input{
    color: #000 !important;

    border-radius: 8px !important;
}

.css-14qh0u1-MuiOutlinedInput-notchedOutline{
    border-radius: 8px !important;
    color: #000 !important;
}


.css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10px 4px !important;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important; */
}

.css-17pmaar-MuiCardContent-root{
    padding: 16px !important;
}

.w-48{
    width: 48%;
}

.m-0-0-0-28{
    margin: 0 0 0 28px;
}

.m-0-0-0-54{
    margin: 0 0 0 54px;
}

.m-0-0-0-6{
    margin: 0 0 0 6px;
}

.m-44-0-0-24{
    margin: 44px 0 0 24px;
}

.m-44-0-0-12{
    margin: 44px 0 0 12px;
}

.mt-20{
    margin-top: 20px;
}

.mt-6{
    margin-top: 6px;
}


/* @media (max-width:768px){
    .d-flex{
        display: inline-flex;
        flex-wrap: wrap;
    }
} */

.flex-wrap {
    flex-wrap: wrap;
}
.gap-40 {
    gap: 40px;
    row-gap: 20px;
}

.gap-10 {
    gap: 10px
}
.text-field {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 170px;
}
.form-label {
    margin-bottom: 20px;
}

.flex-0-5 {
    flex: 0.55 !important
}

.flex-0-25 {
    flex: 0.25 !important
}