.ingredients-table{
    padding: 10px;
    border: 1px solid #ADADAD;
    font-size: 1rem;
}
.ingredients-table table{
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ADADAD;
    border-radius: 10px 10px 0 0;
 }

.ingredients-table tr td + td,
.ingredients-table tr th + th{
   border: 1px solid #ADADAD;
}
.MuiTableCell-root {
    border-top: 1px solid #ADADAD;
    border-bottom: 1px solid #ADADAD;
}
.table-heading{
    font-size: 1.2rem;
    font-weight: 500;
}
.header-heading{
    font-size: 1.5rem;
    font-weight: 500;
}