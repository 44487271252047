.slot-button{
    color: #1B99DF;
    text-decoration: underline;
    cursor: pointer;
}
.pagination-container{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}
.loader-container{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 30vh;
    align-items: center;
}