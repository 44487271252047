.modal-heading{
    font-size: 1.2rem;
    font-weight: 500;
    color: #313131;
}

.header-heading{
    font-size: 1.5rem;
    font-weight: 500;
}
.css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10px 4px !important;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important; */
}

.css-bpbtw5-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 7.5px !important;
}