.dropbtn {
    color: #1B99DF;
}

.dropdown {
    position: absolute;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: relative;
    background-color: #ffffff;
    min-width: 160px;
    max-height: 350px;
    border-radius: 5%;
    z-index: 100000;
    border:1px solid #1B99DF;
    overflow: auto;
}

.dropdown-content p {
    color : #1B99DF;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin: 0;
    border-bottom: 1px solid #ddd;
}

.dropdown-content p:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}


.recipe-table table{
   border-collapse: collapse;
   border-spacing: 0;
   border: 1px solid #ADADAD;
   border-radius: 10px 10px 0 0;
}


.recipe-table tr td + td,
.recipe-table table tr th + th{
   border: 1px solid #ADADAD;
}
.MuiTableCell-root .MuiTableCell-body .MuiTableCell-alignLeft .MuiTableCell-sizeMedium .css-6a6auw-MuiTableCell-root{
    border-top: 1px solid #ADADAD;
    border-bottom: 1px solid #ADADAD;
}
